<template>
  <div class="mainContent">
    <img id="page-header-image" v-if="header_image" :src="header_image.data.full_url" :title="header_image.title"
         class="headerImage"
         lazy-src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAABLAAAAAyCAYAAABMFiIPAAABcklEQVR42u3YQQEAMAgAIU2+6K7GPSAGezNvAAAAACBqBRYAAAAAZQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaQILAAAAgDSBBQAAAECawAIAAAAgTWABAAAAkCawAAAAAEgTWAAAAACkCSwAAAAA0gQWAAAAAGkCCwAAAIA0gQUAAABAmsACAAAAIE1gAQAAAJAmsAAAAABIE1gAAAAApAksAAAAANIEFgAAAABpAgsAAACANIEFAAAAQJrAAgAAACBNYAEAAACQJrAAAAAASBNYAAAAAKQJLAAAAADSBBYAAAAAaR+2qUrPM1FL2wAAAABJRU5ErkJggg==">
    <v-container v-if="loading" fill-height fluid>
      <v-row align="center" justify="center">
        <v-progress-circular
            indeterminate
            color="primary"
        ></v-progress-circular>
      </v-row>
    </v-container>
    <v-container v-if="error" class="mt-2" fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <div class="error">{{ error }}</div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="page" class="mt-2">
      <v-row justify="space-around">
        <v-col sm="12" md="10" lg="9" xl="8">
          <div v-html="page.content" class="content"></div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import client from '../directus';

export default {
  name: 'Page',
  props: {
    path: String,
  },
  data() {
    return {
      loading: false,
      page: null,
      error: null,
      header_image: null,
    }
  },
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.loadPage()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'loadPage'
  },
  methods: {
    async getPage(name) {
      try {
        let result = await client.getItems('page', {
          filter: {
            path: {
              eq: name
            }
          },
          fields: "*, header_image.*.*",
          single: true
        });
        if (!result) {
          return [];
        }
        return result.data;
      } catch (e) {
        console.error(e);
      }
    },
    async loadPage() {
      this.error = this.page = null;
      this.loading = true;
      try {
        const data = await this.getPage(this.path);
        if (data === []) {
          this.page = null;
          this.loading = false;
          this.error = "Leider nichts gefunden";
          return Promise.reject(this.error);
        }
        this.page = data;
        this.header_image = data.header_image;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.error = error;
        console.error("There was an error!", error);
      }
    },
  }
}
</script>
<style scoped>
.mainContent {
  min-height: 100%;
  max-width: 100%;
}

.pageContent {
  max-width: 60%;
}

.headerImage {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
  vertical-align: bottom;
  border: 0;
}

.content {
  font-size: 16px;
}

.content p {
  margin-bottom: 16px;
}

.content p strong {
  color: black;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 24px;
  font-weight: 500;
}

.content h1,.content h2,.content h3 {
  font-family: 'Lato', 'Roboto', 'Calibri', sans-serif;
  font-weight: 700;
  color: #f7a600;
  line-height: 1.1;
}

.content h2, h3 {
  font-weight: 600;
}

.content h3 {
  font-size: 24px;
  margin-top: 50px;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: black;
  letter-spacing: 2px;
}
</style>